import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "Is there a fee to use Wedding Pricer?",
    desc: `Wedding Pricer is completely free to use for couples planning a wedding. We take a small fee from vendors when you work with them, that’s how we make money (and send you free gift cards!)`,
    expand: "a",
  },
  {
    title: "When do I get my gift cards for using Wedding Pricer?",
    desc: `You’ll get your gift cards after we have confirmed with your vendor that they have provided their services. This will usually be 4-6 weeks after your wedding, and after you’ve uploaded a copy of your vendors invoice. This allows us to confirm that you worked together.`,
    expand: "b",
  },
  {
    title: "How do I create a post and get vendors to submit requests to work with me for my wedding?",
    desc: `You can sign up using this <a href='/signup'>link</a> and follow the prompts, it’s really easy. You can include a lot of detail if you want something specific, and less detail if you’re not completely sure what you want just yet. You’ll be able to message vendors through your Wedding Pricer account, and you can discuss options with them. `,
    expand: "c",
  },
  {
    title: "Is there a way to verify the quality of the vendors?",
    desc: `The best way to verify the quality of a vendor is to communicate with them, read reviews about their business, and have them provide examples of their work. Everyone that plans a wedding wants something different, so we let our users like you do their own evaluation.`,
    expand: "d",
  },
  {
    title: "What if I don’t receive any bids?",
    desc: `There might not be vendors that meet your criteria. Wedding Pricer will do all it can to send your post out to vendors both inside and outside our network to find vendors that want to work with you. You can also try adjusting your date or budget range to see if they are the reason vendors are unable to submit bids.`,
    expand: "e",
  },
  {
    title: "Can I update or edit my post after it’s been created?",
    desc: `Absolutely! You can do all of this inside your account. Sign up <a href='/signup'>here</a> if you don’t have an account already and you’ll be able to see how to manage your post.`,
    expand: "f",
  },
  {
    title: "I’m not set on a date, do I have to pick one to make a post?",
    desc: `No, you’re able to submit a number of dates. Vendors will tell you the dates they are available and you can coordinate from there. The best bet is to lock in a venue first and then look for your other vendors, but with Wedding Pricer you can start to evaluate all vendors at once to get a sense of who you want to work with.`,
    expand: "g",
  },
  {
    title: "Can I make multiple posts? I’m not sure where I want to get married.",
    desc: `Yes! You can evaluate multiple cities at once by submitting multiple posts. It’s really easy, and you’ll be able to decide which location makes more sense for you.`,
    expand: "h",
  },
  {
    title: "How do I finalize a booking with a vendor?",
    desc: `Each vendor will have their own process of how to work with them, Wedding Pricer doesn’t dictate this. We connect people looking for wedding vendors with vendors looking for clients, the rest is up to you!`,
    expand: "i",
  },
  {
    title: "What should I include in my post to attract the best bids?",
    desc: `The more detail you have on what you want the better. You might not receive the most bids if you’re specific, but you will receive the best because they’ll be bidding with your specifications in mind.`,
    expand: "k",
  },
  {
    title: "What happens if I need to cancel my event?",
    desc: `We ask you to take down your post so that vendors don’t keep submitting bids but you can always submit another post in the future.`,
    expand: "l",
  },
  {
    title: "What information do you share with the vendors?",
    desc: `The posts that vendors see only include your first name and your wedding requirements so they have enough information to bid. You don’t exchange any credit card information through our platform. You and the vendor exchange information outside of the platform when you decide to work with each other.`,
    expand: "m",
  },
];

const FaqFour = () => {
  return (
    <div
      className="accordion-style-four"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p dangerouslySetInnerHTML={{ __html: item.desc }} />
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqFour;
