import React from "react";

const About = () => {
  return (
    <div className="row align-items-center">
      <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
        <img src="images/assets/reimages/Speech to text-pana.svg" alt="Work illustrations by Storyset" className="m-auto" />
      </div>
      {/* End .col */}

      <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1200">
        <div className="text-wrapper">
          {/* <div className="client-info font-rubik">
            Over <span>150,000+ client</span>
          </div> */}
          <div className="title-style-five">
            <h2>
              Wedding Pricer makes finding wedding vendors<br/>
              <span>easy</span>
            </h2>
          </div>
          <p className="font-rubik">
          Finding wedding vendors can be challenging. Wedding Pricer was created to make it a little bit easier. Instead of calling on different venues, photographers, bakers, dressmakers, stylists, videographers, DJ’s, and bands asking for their rates and availability we get them to come to you. 
          </p>
          <p className="font-rubik">
          Make an account, describe exactly what you’re looking for, input your desired budget, the date or dates that work for you, and the vendors that are interested in working with you will send you a message. 
          </p>
          <p className="font-rubik">
          Coordinate with them as you normally would, and let Wedding Pricer know when you’ve found someone through our platform - we’ll send you a gift card. 
           </p>
          <div className="name font-slab">It's that easy!</div>
        </div>
        {/* /.text-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default About;
