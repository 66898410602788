import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/Header";
import BlogStyle5 from "../../../components/blog/BlogStyle5";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import FooterFour from "../../../components/footer/FooterFour";

const BlogV5 = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>Wedding Pricer Blog</title>
      </Helmet>
      <Header />

      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <h2 className="font-gilroy-bold">
                Wedding Pricer blog
              </h2>
            </div>
            <div className="col-lg-9 m-auto">
              <p className="font-rubik">
                Stay up to date on trends in the wedding industry with timely updates from the team at Wedding Pricer
              </p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>

      <div className="feature-blog-four  blog-page-bg">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">
            {/* <BlogStyle5 /> */}
          </div>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <FooterFour />
            {/* <Footer /> */}
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            {/* <CopyRight /> */}
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default BlogV5;
