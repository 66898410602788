import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const SignUpUserComplete = () => {

  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Sign Up || Wedding Pricer</title>
      </Helmet>
      <div className="user-data-page clearfix d-lg-flex">
        <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
          <h3 className="font-rubik">
            The perfect wedding vendors are right around the corner..
          </h3>
          <div className="illustration-holder">
            <img
              src="/images/assets/reimages/Marriage-bro.svg"
              alt="Work illustrations by Storyset"
              className="illustration"
            />
          </div>
        </div>

          <div className="form-wrapper">
            <div className="d-flex justify-content-between">
              <Link className="font-rubik go-back-button" to="/">
                Go to home
              </Link>
            </div>
            <div className="mt-120">
              <h2>Sign up complete, please check your email!</h2>
            </div>
          </div>

      </div>
    </div>
  );
};

export default SignUpUserComplete;
