import React from "react";
import { Link } from "react-router-dom";
import FeaturesEight from "../features/FeaturesEight";

const HeroBannerFour = () => {
  return (
    <div className="hero-banner-four">
      <img
        src="images/shape/100.svg"
        alt="shape"
        className="shapes shape-four"
      />
      <img
        src="images/shape/101.svg"
        alt="shape"
        className="shapes shape-five"
      />
      <img
        src="images/shape/102.svg"
        alt="shape"
        className="shapes shape-six"
      />
      <img
        src="images/shape/103.svg"
        alt="shape"
        className="shapes shape-seven"
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-lg-last">
            <div className="illustration-holder">
              
              <img src="images/assets/reimages/Wedding planner-pana.svg" alt="Work illustrations by Storyset" className="illustration" />
              
            </div>
            {/* <p className="review-text">
              Over <span>150,000+ client</span> all over the world.
            </p> */}
          </div>
          {/* End .col */}

          <div className="col-lg-6 order-lg-first">
            <div className="text-wrapper">
              <h1 data-aos="fade-up" data-aos-duration="1200">
                <span>Wedding</span> Pricer is where wedding vendors find you.
              </h1>
              <p
                className="sub-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                Sign up to start receiving proposals from local vendors that want to help you with your wedding.
              </p>
              <p
                className="sub-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                Get up to $425 in gift cards when you work with vendors you find through Wedding Pricer.
              </p>
              <Link
                to="/login"
                className="theme-btn-five"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                {" "}
                Get proposals now
              </Link>
            </div>
            {/* /.text-wrapper */}
          </div>
          {/* End .col */}
        </div>
      </div>


    </div>
  );
  //  {/* /.hero-banner-four */}
};

export default HeroBannerFour;
