import React from "react";
import { Link } from "react-router-dom";

const MegaMenu = () => {
  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown position-static">
        <Link className="nav-link" to="/blog">
          Blog
        </Link>

      </li>
      {/* End li */}
      <li className="nav-item dropdown">
        <a className="nav-link dropdown" href="/signup/vendor">
          Vendor signup
        </a>
      </li>
    </ul>
  );
};

export default MegaMenu;
